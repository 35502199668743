<template>
  <div class="container">
    <div class="row">
      <div class="col s12 left-align">
        <h1>Como conectar MetaMask a Binance Smart Chain</h1>
        <h2>
          Conectando MetaMask a Binance Smart Chain podrás operar en el TMPR, Registro Público de Marcas
        </h2>
        <p>En este video te explicamos rápidamente como instalar y conectar  MetaMask a Binance Smart Chain para poder interagir en el TMPR, Registro Público de Marcas</p>
        <ul>
          <li>
            <h5>Instalar y configurar MetaMask</h5>
            <p>En primer lugar, deberás desplazarte hasta la <a href="http://https://metamask.io/download" target="_blank">página de descarga</a> de MetaMask. Una vez allí, selecciona la plataforma que estás utilizando, y sigue los pasos.</p>
            <p>A continuación, continúa con la instalación especificada por la app. Sigue adelante y clica en "Create a Wallet" (crear un monedero).</p>
            <p>¡Y eso es todo! Ahora deberías poder ver tu monedero, listo para enviar y recibir fondos.</p>
          </li>
          <li>
            <h5>Configurar el monedero</h5>
            <p>Deberemos acceder a Settings (ajustes) para apuntar el monedero hacia los nodos de Binance Smart Chain.</p>
            <p>En la página de "Settings" (ajustes), debemos localizar el menú de "Networks" (redes).</p>
            <p>Deberemos clicar en Add Network (añadir red) en la esquina superior derecha, para así añadir manualmente la de Binance Smart Chain –ya que no viene integrada con MetaMask. Existen dos redes que podremos utilizar: la testnet o la mainnet.</p>
           </li>
           <li>
            <h5>Mainnet (ésta es probablemente la que andes buscando)</h5>
            <p>Network name: Smart Chain</p>
            <p>New RPC URL: https://bsc-dataseed.binance.org/</p>
            <p>ChainID: 56</p>
            <p>Symbol: BNB</p>
            <p>Block Explorer URL: https://bscscan.com</p>
            <p>Una vez que guardes la red y regreses a la vista principal, notarás dos cosas: la red se ha configurado automáticamente a la que acabas de ingresar, y las unidades ya no están denominadas en ETH, sino en BNB.</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
